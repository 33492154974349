// Include Sass Files
@import "inc/variables/colors.scss";
@import "inc/variables/fonts.scss";
@import "inc/variables/others.scss";

@import "inc/mixins/media-query.scss";
@import "inc/mixins/flex.scss";


@include for-phone-up{

    body{
        width: 100%;
        overflow-x: hidden;
    }

    #home{
        overflow-x: hidden;
    }

    #modal{

        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.9);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 500;
        overflow-y: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        display: none;
        opacity: 0;

        .modal-close{
            position: relative;
            img{
                position: absolute;
                right: 0;
                width: 25px;
                height: 25px;
                top: -20px;
                cursor: pointer;
            }
        }

        .modal-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h3{
                width: 100%;
                font-size: 20px;
                color: #0fde8e;
                font-family: "HelveticaNeueBold";
                margin-bottom: 10px;
                text-align: center;
            }

            .modal-text{
                text-align: center;
                width: 100%;
                margin-top: 20px;
                margin-bottom: 20px;
                font-family: "HelveticaNeueBold";
                font-size: 16px;
                line-height: 1.2;
            }

            .pink{
                color: #d618a6;
            }

            .white{
                color: #FFF;
            }

            .green{
                color: #0fde8e;
            }

            .modal-link{
                padding: 10px 20px 10px 20px;
                background-color: #0fde8e;
                border-radius: 50px;
                text-decoration: none;
                color: #FFF;
                font-size: 18px;
                text-align: center;
                width: 100%;
                max-width: 400px;
                margin-bottom: 20px;
            }

        }

    }


}

@include for-tablet-up{


}

@include for-desktop-up{

    #modal{

        padding-top: 150px;
        padding-bottom: 150px;

        .modal-close{

            img{
                width: 50px;
                height: 50px;
                top: -50px;
            }
        }

        .modal-content{

            h3{
                font-size: 34px;
                margin-bottom: 20px;
            }
            
            .modal-text{
                
                margin-top: 30px;
                margin-bottom: 30px;
                font-size: 20px;
            }

            .modal-link{
                padding: 10px 20px 10px 20px;
                font-size: 24px;
            }

        }

    }


}

@include for-tv-up{


}